export function Timepicker({
                               $input,
                               initTime = '09:00',
                           }) {

    const classPrefix = 'timepicker';
    const dataPrefix = 'data-timepicker';

    const $template = document.querySelector(`[${dataPrefix}-template]`);
    const $templateItem = document.querySelector(`[${dataPrefix}-template-item]`);
    const openClass = `${classPrefix}--open`

    let $selectors = null
    let disabled = $input.disabled

    const changeEvent = new CustomEvent('change', {
        detail: {origin: 'script'}
    });

    let hoursSelector = null
    let minutesSelector = null
    let inputCounter = 0

    init()

    function init() {
        const $clone = document.importNode($template.content, true);
        const $timepicker = $clone.querySelector(`[${dataPrefix}-wrapper]`)
        const $inputWrapper = $clone.querySelector(`[${dataPrefix}-input-wrapper]`)
        $selectors = $clone.querySelector(`[${dataPrefix}-selectors-wrapper]`)

        const $wrapper = $input.parentNode
        $input.classList.add(`${classPrefix}__input`)
        $inputWrapper.appendChild($input)

        hoursSelector = new CreateSelectorItem({min: 0, max: 23, timePosition: 0, step: 1})
        minutesSelector = new CreateSelectorItem({min: 0, max: 59, timePosition: 1, step: 15})

        $input.addEventListener('keydown', (event) => {
            if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
                return;
            }

            event.preventDefault();

            if (event.key === 'ArrowDown') {
                hoursSelector.down();
            } else if (event.key === 'ArrowUp') {
                hoursSelector.up();
            }
        });

        $input.addEventListener('input', eventInputChangeHandler);

        $input.addEventListener('change', (event) => {
            eventInputChangeHandler(event, true)
        });

        $input.addEventListener('blur', (event) => {
            eventInputChangeHandler(event, true)
        });

        function eventInputChangeHandler(event, withRound = false) {
            if (event.detail && event.detail.origin === 'script') {
                return;
            }

            correctDateInInput(withRound)
        }

        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'attributes') {
                    if (mutation.attributeName === 'min' || mutation.attributeName === 'max') {
                        correctDateInInput(true)
                    }
                }
            });
        });

        observer.observe($input, {
            attributes: true,
            attributeFilter: ['min', 'max']
        });

        document.addEventListener('click', (event) => {
            if (disabled) {
                $timepicker.classList.remove(openClass)
                return;
            }

            if (!$timepicker.contains(event.target) && $timepicker !== event.target && event.target !== $input) {
                $timepicker.classList.remove(openClass)
            } else if (event.target === $input) {
                $timepicker.classList.toggle(openClass)
            }
        })

        $wrapper.appendChild($clone);
    }

    function CreateSelectorItem({min, max, step, timePosition}) {
        const $clone = document.importNode($templateItem.content, true);
        const $item = $clone.querySelector(`[${dataPrefix}-item]`)
        const $value = $clone.querySelector(`[${dataPrefix}-value]`)
        const $up = $clone.querySelector(`[${dataPrefix}-up]`)
        const $down = $clone.querySelector(`[${dataPrefix}-down]`)

        $item.addEventListener('keydown', (event) => {
            if (disabled) return;

            if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
                return;
            }

            event.preventDefault();

            if (event.key === 'ArrowDown') {
                $down.click();
            } else if (event.key === 'ArrowUp') {
                $up.click();
            }
        });

        $up.addEventListener('click', () => {
            if (disabled) return;

            let value = min

            if ($input.value !== '') {
                value = parseInt($input.value.split(':')[timePosition])
                value = value + step > max ? min : value + step
            }

            setValue(formatValue(value))
        })

        $down.addEventListener('click', () => {
            if (disabled) return;

            let value = min

            if ($input.value !== '') {
                value = parseInt($input.value.split(':')[timePosition])
                value = value - step < min ? Math.abs((max + 1) - step) : value - step
            }

            setValue(formatValue(value))
        })

        setValue(formatValue(initTime.split(':')[timePosition]))

        $selectors.appendChild($clone);

        function setValue(value) {
            $value.textContent = value

            let currentTime = initTime.split(':')

            if ($input.value !== '') {
                currentTime = $input.value.split(':')
                currentTime[timePosition] = value
            }

            $input.value = currentTime.join(':')
            $input.dispatchEvent(changeEvent)
        }

        function update() {
            let value = min

            if ($input.value !== '') {
                value = Math.round(parseInt($input.value.split(':')[timePosition]) / step) * step;
                $value.textContent = formatValue(value)
            }

        }

        this.update = update
        this.setValue = setValue
        this.up = () => $up.click()
        this.down = () => $down.click()
    }

    function correctDateInInput(withRound) {
        const check = checkDateFromInput();

        let newTime = null;

        if (check === -1) {
            newTime = $input.min
        } else if (check === 1) {
            newTime = $input.max
        } else if (check === 0) {
            newTime = initTime
        }

        if (newTime) {
            $input.value = newTime
            $input.dispatchEvent(changeEvent)
        }

        if ($input.hasAttribute('step') && withRound && $input !== document.activeElement) {
            const step = parseInt($input.getAttribute('step'))
            const timeInSecond = parseInt($input.value.split(':')[0]) * 3600 + parseInt($input.value.split(':')[1]) * 60
            const timeInSecondInStepRange = Math.round(timeInSecond / step) * step


            if (timeInSecond !== timeInSecondInStepRange) {
                const hours = Math.floor(timeInSecondInStepRange / 3600)
                const minutes = Math.floor((timeInSecondInStepRange - hours * 3600) / 60)

                $input.value = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
                $input.dispatchEvent(changeEvent)
                hoursSelector.update()
                minutesSelector.update()
            }
        }
    }

    function checkDateFromInput() {
        if ($input.value === '') {
            return 0;
        }

        if (!$input.min && !$input.max) {
            return true;
        }

        const currentTime = $input.value.split(':')
        const currentHours = +currentTime[0]
        const currentMinutes = +currentTime[1]

        if ($input.min) {
            const minTimeSplit = $input.min.split(':')
            const minHours = +minTimeSplit[0]
            const minMinutes = +minTimeSplit[1]

            if (currentHours < minHours || (currentHours === minHours && currentMinutes < minMinutes)) {
                return -1;
            }
        }

        if ($input.max) {
            const maxTimeSplit = $input.max.split(':')
            const maxHours = +maxTimeSplit[0]
            const maxMinutes = +maxTimeSplit[1]

            if (currentHours > maxHours || (currentHours === maxHours && currentMinutes > maxMinutes)) {
                return 1;
            }
        }


    }

    function formatValue(value) {
        value = parseInt(value)
        value = value < 10 ? `0${value}` : value
        return value
    }
}
